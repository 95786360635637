import { CardTitle, CardText } from 'reactstrap'
import {API_STATIC_MEDIA} from "../../index";


const AdvantageCard = ({ cardTitle, cardContent }) => (
    <div className='advCard'>
        <img src={API_STATIC_MEDIA+'icons/card_star.svg'} alt='Star'></img>
        <br/><br/>
        <CardTitle tag="h5">
        {cardTitle}
        </CardTitle>
        <br/>
        <CardText>
            {cardContent}
        </CardText>
    </div>
);

export default AdvantageCard;