import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import React, { useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { Row, Col } from 'reactstrap'
import AdvantageCard from './advantage_card';


function Jijica2(props) {
    const gltf = useLoader(GLTFLoader, '/3d/jijka2.glb')

    const [rotation, setRotation] = useState([0, 0, 0]);
    const [mouseDown, setMouseDown] = useState(false);

    const handlePointerDown = (event) => {
        event.stopPropagation();
        event.target.setPointerCapture(event.pointerId);
        setMouseDown(true);
    };

    const handlePointerMove = (event) => {
        event.stopPropagation();
        const deltaMove = {
        x: event.movementX * -0.01,
        y: event.movementY * 0.01,
        };
        setRotation((prevRotation) => [
        prevRotation[0] + deltaMove.y,
        prevRotation[1] + deltaMove.x,
        prevRotation[2],
        ]);
    };

    const handlePointerUp = (event) => {
        event.stopPropagation();
        event.target.releasePointerCapture(event.pointerId);
        setMouseDown(false);
    };

    useFrame((state, delta) => {
        if (!mouseDown) {
            gltf.scene.rotation.z += delta*0.5;
        }
        else {
            gltf.scene.rotation.set(...rotation);
        }
    })
    return <primitive onPointerDown={handlePointerDown} onPointerMove={handlePointerMove} onPointerUp={handlePointerUp}  object={gltf.scene} scale={2} />
  
}

const SkillsBlock = (index) => (
    <div id="skills">
        <div className="titleHeader">
            <h1 className="leftBlock">
                <p className='subTitle'>&lt;Skills&gt;</p>
                The skills I have as a developer
                <br /><br />
                <span>
                    <ul className='subTitle mob-text-center'>
                        <li>GmodLUA: Clientside, Serverside</li>
                        <li>Web: pure HTML/CSS/JS/PHP.</li>
                        <li>Web Stack: ReactJS, Django, Bootstrap</li>
                        <li>C#: Unity, WPF, WinForms</li>
                        <li>DB: MySQL, Sqlite, MongoDB</li>
                    </ul>
                </span>
            </h1>

            <div className='rightModel'>
                <Canvas
                    camera={{ position: [0, 10, 0] }}
                >
                    <ambientLight intensity={Math.PI / 2} />
                    <spotLight position={[20, 20, 20]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                    <pointLight position={[-20, -20, -20]} decay={0} intensity={Math.PI} />
                    <Jijica2 position={[0, 0, 0]} />
                </Canvas>
            </div>
        </div>

        <br className='mobile-show'/><br lassName='mobile-show'/>


        <p className='subTitle'>&lt;Advantages&gt;</p>
        <Row>
            <Col sm="4" className='mx-auto adaptive-col'><AdvantageCard cardTitle="Low price" cardContent="I won't take too much money. Also, I'm always ask the customer to set the price"/></Col>
            <Col sm="4" className='mx-auto adaptive-col'><AdvantageCard cardTitle="Support" cardContent="I will try to answer all your questions and solve any problems related to my scripts"/></Col>
            <Col sm="4" className='mx-auto adaptive-col'><AdvantageCard cardTitle="Experience" cardContent="I made a lot of addons. You can see examples of my works below"/></Col>
        </Row>
    </div>
);

export default SkillsBlock;