import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

import {API_STATIC_MEDIA} from "../../index";

function WorksBlock (props) {
    const carouselItems = [
        {
          id: 1,
          altText: 'Amtasi Loading Screen',
          caption: '<Amtasi Loading Screen>',
          src: `${API_STATIC_MEDIA}image/amtasi_load.jpg`,
        },
        {
          id: 2,
          altText: 'The Strongest Batch Escape Menu',
          caption: '<The Strongest Batch Escape Menu>',
          src: `${API_STATIC_MEDIA}image/tsb_escape.jpg`,
        },
        {
          id: 3,
          altText: 'Chernobyl Warfare Launcher',
          caption: '<Chernobyl Warfare Launcher>',
          src: `${API_STATIC_MEDIA}image/chwf_launcher.jpg`,
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    
    const slides = carouselItems.map((item) => {
        return (
          <CarouselItem
            tag="div"
            src={item.src}
            key={item.id}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >
            <div className='carouselCaptHold'>
                <img src={item.src} alt={item.altText} className='carouselImg'/>
                <p className="carouselCapt">{item.caption}</p>
            </div>
          </CarouselItem>
        );
    });

    return (
        <div id="works">
            <h1 className="leftBlock">
                <p className='subTitle'>&lt;My works&gt;</p>
                My works for demonstration
                <br /><br />
            </h1>
            <br/><br/>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators className='carouselInd' items={carouselItems} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                {slides}
                <button
                className='carouselElement carouselLeft'
                onClick={previous}
                ><img src={API_STATIC_MEDIA+"icons/arrow_right.svg"} alt='<'/></button>
                <button
                className='carouselElement carouselRight'
                onClick={next}
                ><img src={API_STATIC_MEDIA+"icons/arrow_right.svg"} alt='>'/></button>
            </Carousel>
            <br/><br/><br/>
        </div>
    );
};

export default WorksBlock;