import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FixedNavbar from "../appNavbar/FixedNavbar";
import LandingPage from "../../pages/landing/landing";
import LinksPage from "../../pages/links/links";
import FeedbackPage from "../../pages/feedback/feedback";
import ArticlesPage from "../../pages/articles/articles";
import { Container } from 'reactstrap'

function App() {
    return (
        <div className="App" data-bs-theme="dark">
            <BrowserRouter>
                <FixedNavbar/>
                <Container>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/links" element={<LinksPage />} />
                        <Route path="/feedback" element={<FeedbackPage />} />
                        <Route path="/articles" element={<ArticlesPage />} />
                    </Routes>
                </Container>
            </BrowserRouter>
        </div>
    );
}

export default App;