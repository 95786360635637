import ArticleButton from "./article_button";
import {useEffect, useState} from "react";
import { Row, Col } from 'reactstrap'
import axios from "axios";
import {API_URL} from "../../index"
import {useLocation} from 'react-router-dom';

import parse from 'html-react-parser';

function str_pad(n) {
    return String("00" + n).slice(-2);
}

const ArticlesPage = (index) => {
    const [articles, setArticles] = useState([])

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const aid = searchParams.get('id');

    useEffect(()=>{
        getArticles()
    },[])

    const getArticles = (data)=>{
        axios.get(API_URL+"articles/").then(data => setArticles(data.data))
    }

    // const resetState = () => {
    //     getArticles();
    // };
    if (!aid) {
        return (
        <>
            <h1 className="singleBlock">
                <p className='subTitle'>&lt;Articles&gt;</p>
                Blog (all articles written in russian)
            </h1>
            <br/><br/>
            <Row>
            {!articles || articles.length <= 0 ? (
                    <Col sm="3" className='adaptive-col mx-5'>
                        <b>Пока ничего нет</b>
                    </Col>
            ) : articles.map(article => (
                    <Col sm="3" className='adaptive-col mx-5'><ArticleButton articleTitle={article.header} articleShort={article.short} articleID={article.id}/></Col>
                )
            )}
            </Row>
        </>
        )
    }
    else {
        var article = null;
        var parsedAid = parseInt(aid);
        articles.forEach(element => {
            if (element.id === parsedAid) {
                article = element;
            }
        });
        if (!article) {
            return (
                <div>
                    <h1 className="singleBlock">
                        <p className='subTitle'>&lt;Error&gt;</p>
                        Article not found
                    </h1>
                    <br/><br/>
                </div>
            )
        }
        else {
            var parsedArticle = parse(article.text);
            var pDate = new Date(article.post_date);
            console.log(pDate);
            return (
                <div>
                    <h1 className="singleBlock">
                        <p className='subTitle'>&lt;Article&gt;</p>
                        {article.header}
                    </h1>
                    <br/><br/>
                    {parsedArticle}
                    Статья опубликована {str_pad(pDate.getDate())}.{str_pad(pDate.getMonth()+1)}.{pDate.getFullYear()} в {str_pad(pDate.getHours())}:{str_pad(pDate.getMinutes())}:{str_pad(pDate.getSeconds())} по МСК
                    <br/><br/>
                </div>
            )
        }
    }
};

export default ArticlesPage;