import { useState } from 'react';
import {API_STATIC_MEDIA} from "../../index";
import '../app/App.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function FixedNavbar() {
    const [navImg, setNavImg] = useState(`url(${API_STATIC_MEDIA}icons/arrow_up.svg)`);
    const [navOpen, setNavOpen] = useState(false);

    function toggleNav() {
        if (navImg === `url(${API_STATIC_MEDIA}icons/arrow_up.svg)`) { // Open Nav
            setNavImg(`url(${API_STATIC_MEDIA}icons/arrow_down.svg)`);
            setNavOpen(true);
        }
        else { // Close Nav
            setNavImg(`url(${API_STATIC_MEDIA}icons/arrow_up.svg)`);
            setNavOpen(false);
        }
    }

    function hideOffscreen() {
        setNavImg(`url(${API_STATIC_MEDIA}icons/arrow_up.svg)`);
        setNavOpen(false);
    }

    function toggleLang() {
        
    }

    return (
        <div>
            <nav className="offscrenNavbar" style={{visibility: navOpen ? "visible" : "hidden", opacity: navOpen ? 1 : 0}}>
                <div>
                    <p>
                        <Link to="/#" className='navLink' onClick={hideOffscreen}>Landing</Link>
                    </p>
                    <p>
                        <Link to="/#skills" className='navLink' onClick={hideOffscreen}>Skills</Link>
                    </p>
                    <p>
                        <Link to="/#works" className='navLink' onClick={hideOffscreen}>My works</Link>
                    </p>
                    <p>
                        <Link to="/links" className='navLink' onClick={hideOffscreen}>Links</Link>
                    </p>
                    <p>
                        <Link to="/shop" className='navLink' onClick={hideOffscreen}>Shop</Link>
                    </p>
                    <p>
                        <Link to="/articles" className='navLink' onClick={hideOffscreen}>Articles</Link>
                    </p>
                    <p>
                        <Link to="/feedback" className='navLink' onClick={hideOffscreen}>Feedback</Link>
                    </p>
                </div>
            </nav>
            <button id="nav-arrow" onClick={toggleNav} className="nav-arrow" style={{backgroundImage: navImg}}></button>
            {/* <Link to="/feedback">
                <button className="nav-feedback" style={{backgroundImage: `url(${API_STATIC_MEDIA}icons/post_mail.svg)`}}><span className="nav-feedback-txt">&lt;Contact me&gt;</span></button>
            </Link> */}
            <div className="nav-links-right">
            <a className="nav-discord" href="https://pavetr.ru/ds" ><img src={API_STATIC_MEDIA+'icons/discord_white.svg'} alt='Discord'></img></a>
            <a className="nav-tg" href="https://t.me/pvtrchn"><img src={API_STATIC_MEDIA+'icons/tg_white.svg'} alt='Telegram'></img></a>
            <button onClick={toggleLang} className="nav-lang">en</button>
            </div>
        </div>
    )
}