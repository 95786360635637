import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

export const API_URL = "https://pavetr.ru/api/"
export const API_STATIC_MEDIA = "https://pavetr.ru/static/"

export const RECAPTCHA_PUBLIC_KEY = "6LdFveIpAAAAAFIahf5-H6Bl90ZuOLmFTq2DhlUs"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

reportWebVitals();