import React, { useState, useRef } from "react";
import axios from "axios";
import {API_URL,RECAPTCHA_PUBLIC_KEY} from "../../index"

import ReCAPTCHA from 'react-google-recaptcha'

const FeedbackPage = (index) => {
    function textAreaAdjust(element) {
        element.target.style.height = "1px";

        var text = element.target.value;   
        var lines = text.split("\n");
        var count = lines.length;

        element.target.style.height = (30+25*count)+"px";
    }
    const [feedbackData, setFeedbackData] = useState({
        email: "",
        message: "",
    });

    const recaptcha = useRef()

    const handleChange = (e) => {
        setFeedbackData({ ...feedbackData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            alert('Вы не прошли проверку reCAPTCHA!')
        } else {
            try {
                feedbackData["captcha"] = captchaValue;
                await axios.post(
                  API_URL+"feedback_form/",
                  feedbackData
                );
                alert("Запрос отправлен! Ожидайте ответа на указанный email!");
                setFeedbackData({ email: "", message: "" });
            } catch (error) {
                console.error("Error submitting form:", error);
                alert("Ошибка при отправке форма. Смотрите консоль для подробностей");
            }
        }
      };

    return (
    <>
        <h1 className="singleBlock">
            <p className='subTitle'>&lt;Support&gt;</p>
            Feedback
        </h1>
        <br/><br/>
        <form onSubmit={handleSubmit} method="POST">
            <h5>Ваш E-Mail</h5>
            <input
                name="email"
                type="email"
                placeholder="admin@pavetr.ru"
                className="outlinedField"
                value={feedbackData.email}
                onChange={handleChange}
                required
            />
            <br/><br/><br/>
            <h5>Текст</h5>
            <textarea
                name="message"
                onChange={handleChange}
                value={feedbackData.message}
                onKeyUp={textAreaAdjust.bind(this)}
                className="outlinedField"
                placeholder="Подробно опишите суть вашего вопроса"
                style={{height: "100px"}}
                required
            ></textarea>
            <br/><br/>
            <ReCAPTCHA ref={recaptcha} sitekey={RECAPTCHA_PUBLIC_KEY} />
            <br/><br/>
            <input type="submit" value={"Отправить"} className="big-button"></input>
        </form>
    </>);
};

export default FeedbackPage;