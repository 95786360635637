import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import React, { useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { HashLink as Link } from 'react-router-hash-link';

function Jijica1(props) {
    const gltf = useLoader(GLTFLoader, '/3d/jijka1.glb')

    const [rotation, setRotation] = useState([0, 0, 0]);
    const [mouseDown, setMouseDown] = useState(false);

    const handlePointerDown = (event) => {
        event.stopPropagation();
        event.target.setPointerCapture(event.pointerId);
        setMouseDown(true);
    };

    const handlePointerMove = (event) => {
        event.stopPropagation();
        const deltaMove = {
        x: event.movementX * -0.01,
        y: event.movementY * 0.01,
        };
        setRotation((prevRotation) => [
        prevRotation[0] + deltaMove.y,
        prevRotation[1] + deltaMove.x,
        prevRotation[2],
        ]);
    };

    const handlePointerUp = (event) => {
        event.stopPropagation();
        event.target.releasePointerCapture(event.pointerId);
        setMouseDown(false);
    };

    useFrame((state, delta) => {
        if (!mouseDown) {
            gltf.scene.rotation.z += delta*0.5;
        }
        else {
            gltf.scene.rotation.set(...rotation);
        }
    })
    return <primitive onPointerDown={handlePointerDown} onPointerMove={handlePointerMove} onPointerUp={handlePointerUp}  object={gltf.scene} scale={2} />
  
}

const TitleBlock = (index) => (
    <>
    <div className="titleHeader">
            <h1 className="leftBlock">
                <p className='subTitle'>&lt;Greeting&gt;</p>
                My name is Pavetr. I am a LUA developer from Russia.
                <br /><br />
                <Link to="/#skills">
                    <button className='big-button-land'>About me</button>
                </Link>
                
                <Link to="/feedback">
                    <button className='big-button-land-white'>Contact me</button>
                </Link>
            </h1>
            <div className='rightModel'>
                <Canvas
                    camera={{ position: [0, 6, 0] }}
                >
                    <ambientLight intensity={Math.PI / 2} />
                    <spotLight position={[20, 20, 20]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                    <pointLight position={[-20, -20, -20]} decay={0} intensity={Math.PI} />
                    <Jijica1 position={[0, 0, 0]} />
                </Canvas>
            </div>
        </div>
    </>
);
export default TitleBlock;