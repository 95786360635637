import { CardTitle, CardText } from 'reactstrap'
import {API_STATIC_MEDIA} from "../../index";
import {useNavigate} from 'react-router-dom';


const ArticleButton = ({ articleTitle, articleShort, articleID }) => {
    const navigate = useNavigate();
    return (
        <div className='linkCard' onClick={() => navigate(`/articles?id=${articleID}`)}>
            <img src={API_STATIC_MEDIA+'icons/card_star.svg'} alt='Star'></img>
            <br/><br/>
            <CardTitle tag="h5">
            {articleTitle}
            </CardTitle>
            <br/>
            <CardText>
                {articleShort}
            </CardText>
        </div>
    )
};

export default ArticleButton;