import TitleBlock from './title';
import SkillsBlock from './skills'
import WorksBlock from './works';

const LandingPage = (index) => (
    <>
        <TitleBlock/>
        <SkillsBlock/>
        <WorksBlock/>
        <br/><br/>
    </>
);

export default LandingPage;