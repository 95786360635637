import { Row, Col } from 'reactstrap'
import LinkCard from './link';
import {API_STATIC_MEDIA} from "../../index";

const LinksPage = (index) => (
    <>
        <h1 className="singleBlock">
            <p className='subTitle'>&lt;Links&gt;</p>
            Useful links
        </h1>
        <br/><br/>
        <Row>
            <Col sm="4" className='adaptive-col'><LinkCard cardTitle="Discord" cardContent="Our main community" cardIcon={API_STATIC_MEDIA+'icons/discord_white.svg'} cardLink="https://pavetr.ru/ds"/></Col>
            <Col sm="4" className='adaptive-col'><LinkCard cardTitle="Portfolio" cardContent="Telegram channel with my works" cardIcon={API_STATIC_MEDIA+'icons/briefcase_fill.svg'} cardLink="https://t.me/+jFkE13rk_2owYjE6"/></Col>
            <Col sm="4" className='adaptive-col'><LinkCard cardTitle="Partner" cardContent="Site of sensei_this" cardIcon={API_STATIC_MEDIA+'icons/smile.svg'} cardLink="https://senseithis.netlify.app/#hero"/></Col>
            <Col sm="4" className='adaptive-col'><LinkCard cardTitle="Telegram with cheats" cardContent="Telegram channel with cheats on Garry's Mod, CS2, etc." cardIcon={API_STATIC_MEDIA+'icons/tg_white.svg'} cardLink="https://t.me/pavetrsoft"/></Col>
            <Col sm="4" className='adaptive-col'><LinkCard cardTitle="Reserve Telegram" cardContent="If Discord gets banned, all the info will be there" cardIcon={API_STATIC_MEDIA+'icons/tg_white.svg'} cardLink="https://t.me/pvtrchn"/></Col>
        </Row>
    </>
);

export default LinksPage;