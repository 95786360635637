import { CardTitle, CardText } from 'reactstrap'

function forwardLink(link) {
    window.location.href = link;
}

const LinkCard = ({ cardTitle, cardContent, cardIcon, cardLink }) => (
    <div className='linkCard' onClick={() => forwardLink(cardLink)}>
        <img className='linkIco' src={cardIcon} alt='Icon'></img>
        <br/><br/>
        <CardTitle tag="h5">
        {cardTitle}
        </CardTitle>
        <br/>
        <CardText>
            {cardContent}
        </CardText>
    </div>
);

export default LinkCard;